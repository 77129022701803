export function cleanUpSpecialChars(str) {
  return str
      .replace(/[ÀÁÂÃÄÅ]/g,"A")
      .replace(/[àáâãäå]/g,"a")
      .replace(/[ÈÉÊË]/g,"E")
      .replace(/[éèẽê]/g, "e")
      .replace(/[ÍÌÎĨ]/g, "I")
      .replace(/[ìíîĩ]/g, "i")
      .replace(/[ÓÒÔÕ]/g, "O")
      .replace(/[óòôõ]/g, "o")
      .replace(/[ÚÙÛŨ]/g, "U")
      .replace(/[úùũû]/g, "u")
      .replace(/[^a-z0-9]/gi,'');
}
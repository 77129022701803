import React from 'react';

import Header from '../../components/Header';
import ContactUs from '../../components/ContactUsBanner';
import Footer from '../../components/Footer';

import './styles.css';
import TitleBackground from '../../assets/portugues/title-background.jpg';
import LanguageImage from '../../assets/portugues/portugues.png'

import IconClock from '../../assets/icon-clock-blue.png';
import IconLaptop from '../../assets/icon-laptop-blue.png';
import IconPerson from '../../assets/icon-person-blue.png';
import IconBook from '../../assets/icon-book-blue.png';


function Portugues() {

  return (

    <>
      <Header />


      <section id="language">

        
        <div className="banner" style={{backgroundImage: 'url(' + TitleBackground + ')'}}>
          <h1 className="language-title">Portugués</h1>
        </div>

        <div className="container">

          

          <div className="language-details">

            <div className="course-features">

              <h3 className="course-title">Online: adultos</h3>
              <p className="course-duration button button-orange">Cursos Cuatrimestrales</p>
              <div className="course-includes">
                <p>Incluye</p>
                <ul className="course-list">
                  <li className="course-item">
                    <img src={IconClock} alt=""/>
                    <p>
                      16 clases de 120 minutos.
                    </p>
                  </li>

                  <li className="course-item">
                    <img src={IconLaptop} alt=""/>
                    <p>
                      Cada clase dividida en 80 minutos de trabajo sincrónico (los alumnos y la 
                      profesora online al mismo tiempo) y 40 minutos de trabajo asincrónico (los 
                      alumnos y la profesora en distinto tiempo: la profesora asigna actividades 
                      escritas y auditivas, de video o de lectura complementaria y los alumnos 
                      lo realizan a su ritmo en su horario).
                    </p>
                  </li>

                  <li className="course-item">
                    <img src={IconPerson} alt=""/>
                    <p>
                      32 horas de trabajo.
                    </p>
                  </li>

                  <li className="course-item">
                    <img src={IconBook} alt=""/>
                    <p>
                      Todo el material escrito, auditivo, de video y de lectura complementaria.
                    </p>
                  </li>

                </ul>

              </div>
            </div>
            
                          
            <div className="course-image">
              <img src={LanguageImage} alt=""/>

              <div className="image-content-container">
                <p>Inscribete ahora</p>
                <a href="/contacto" className="button button-blue">Aprendé Portugués</a>
              </div>
            </div>

          </div>
        </div>
      </section>

      <ContactUs />

      <Footer />
    </>
  )
}

export default Portugues;
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://back.laboratoriodeidiomas.com.ar',
  timeout:10000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': '*^*oyFMlGPs2sEybiWu2Fcxlpi$20SW!Za0@$'
  }
})

export default api;
import React from 'react';

import './styles.css';
import Header from '../../components/Header';
import Form from '../../components/Form';
import Footer from '../../components/Footer';
import ContactUsBanner from '../../components/ContactUsBanner';

import girlPhoto from '../../assets/home/home-girl.png';
import girlDraw from '../../assets/home/home-dibujo.png';

function Home() {
  return (
    <>
      <Header />

      <section id="contact-us">
        <div className="container">
          <div className="contact-us-left-container">
            <h2>Cursos de <span className="orange">Idiomas</span></h2>

            <p>
              Estudiá en nuestro instituto desde donde estés.
            </p>
          </div>

          <div className="contact-us-right-container">
            <div className="form-container">
              <Form />
            </div>
          </div>

        </div>
      </section>



      <section id="home-courses">
        <div className="container">
          <div className="home-courses-left-container">
            <h2>Inglés, portugués, alemán, italiano y francés</h2>

            <p>Niños, jóvenes y adultos</p>

            {/*
            <a className="button button-blue" href="/cursos/ingles">Ver cursos</a> 
            */}
          </div>

          <div className="home-courses-right-container">
            <img src={girlPhoto} alt=""/>
          </div>
        </div>
      </section>


      <section id="about-us">
        <div className="container">
          <div className="home-about-us-left-container">
            <img src={girlDraw} alt=""/>
          </div>

          <div className="home-about-us-right-container">
            <h3>¿Por qué estudiar en nuestro laboratorio?</h3>

            <p>
              El Laboratorio de Idiomas Adrogué abrió sus puertas en 1987. Desde entonces nuestra intención es acompañar a los alumnos en el camino de aprendizaje al idioma y acercarlos a su cultura. 
              <br/><br/>
              Dictamos clases de inglés, francés , alemán, portugués e Italiano, para grupos formados según edad y nivel de conocimiento.
              Nuestra enseñanza se basa en la búsqueda de excelencia de un clima amistoso y con un staff de profesionales con amplia experiencia y constante capacitación.
              <br/><br/>
              Nuestros programas están alineados con el Marco Común Europeo de Referencia para las Lenguas y sus correspondientes Exámenes Internacionales: First Certificate, Toefl, Delf, Celpe-Bras, Goethe-Zertifikat y Plida entre otros.
            </p>
          </div>
        </div>
      </section>

      
      <ContactUsBanner />

      <Footer />
    </>
  )
}

export default Home;
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.css';
import api from '../../services/api';
import { cleanUpSpecialChars } from '../../utils/stringUtils';

function Form() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [category, setCategory] = useState("");
  const [language, setLanguage] = useState("");
  const [message, setMessage] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const history = useHistory();


  async function handleForm(e) {
    e.preventDefault();

    if (isEmailSent) {
      return;
    } else {
      
      setIsEmailSent(true);

      const params = new URLSearchParams();
      params.append('firstName', firstName);
      params.append('lastName', lastName);
      params.append('email', email);
      params.append('phone', phone);
      params.append('category', category);
      params.append('language', language);
      params.append('content', message);

      try {  
        await api.post('/email/send', params);
  
        let normalizedLanguage = cleanUpSpecialChars(language.toLowerCase());
        history.push(`/gracias?idioma=${normalizedLanguage}`);

      } catch (error) {
        alert('Hubo un error al enviar tu mensaje. Por favor, intenta nuevamente mas tarde o escribinos a info@laboratoriodeidiomas.com.ar')
        setIsEmailSent(false);
      }
    }
  }

  return (
    <>
      <form onSubmit={handleForm} id="contact-form">
        <h3>¡Empezá a aprender idiomas!</h3>
        
        <div className="input-group">
            <input 
              type="text" 
              id="fname" 
              name="fname" 
              placeholder="Nombre"
              required
              value={firstName} 
              onChange={e => setFirstName(e.target.value)}
            />
            <input 
              type="text" 
              id="lname" 
              name="lname"
              placeholder="Apellido" 
              required
              value={lastName} 
              onChange={e => setLastName(e.target.value)}
            />
        </div>

        <input 
          type="email" 
          id="email" 
          name="email" 
          required
          placeholder="Email"
          value={email} 
          onChange={e => setEmail(e.target.value)}
        />

        <div className="input-group">
          <input 
            type="phone" 
            id="phone" 
            name="phone" 
            placeholder="Teléfono"
            value={phone} 
            onChange={e => setPhone(e.target.value)}
          />

          <select id="languages" onChange={e => setCategory(e.target.value)}>
            <option value="Sin especificar" selected >Seleccionar alumno/a</option>
            <option value="Niños">Niños</option>
            <option value="Jóvenes">Jóvenes</option>
            <option value="Adultos">Adultos</option>
            
          </select>
        </div>

        <select id="category" onChange={e => setLanguage(e.target.value)}>
          <option value="Sin especificar" disabled selected hidden>Seleccionar idioma</option>
          <option value="Inglés">Inglés</option>
          <option value="Francés">Francés</option>
          <option value="Portugués">Portugués</option>
          <option value="Alemán">Alemán</option>
          <option value="Italiano">Italiano</option>
        </select>



        <label htmlFor="consulta">Consulta</label>
        <textarea name="consulta" id="form-consulta" value={message} onChange={e => setMessage(e.target.value)}/>
        <button className="button button-orange" type="submit">Comenzar</button>

      </form> 
    </>
  )
}

export default Form;
import React from 'react';

import './styles.css';

function ContactUsBanner() {
  return (
    <section className="contact-us-banner">
        <div className="container">
          <h3>¿Querés estudiar en el Laboratorio de Idiomas Adrogué?</h3>
          <a className="button button-blue" href="/contacto">Contactános</a>
        </div>
      </section>
  )
}

export default ContactUsBanner;
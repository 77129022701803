import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import './styles.css';

function Thanks() {

  return (
    <>
      <Header />

      <div class="thanks-container">
        <h3 class="gracias">Gracias por enviar tu consulta!</h3> 
        <h3 class="gracias">Te responderemos a la brevedad.</h3>
      </div>
      
      <Footer />
    </>
  )
}

export default Thanks;
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import './styles.css';
import api from '../../services/api';
import { cleanUpSpecialChars } from '../../utils/stringUtils';

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [category, setCategory] = useState("");
  const [language, setLanguage] = useState("");
  const [message, setMessage] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const history = useHistory();

  async function handleForm(e) {
    e.preventDefault();

    
    if (isEmailSent) {
      return;
    } else {
      
      setIsEmailSent(true);
      
      const params = new URLSearchParams();
      params.append('firstName', name);
      params.append('email', email);
      params.append('phone', phone);
      params.append('category', category);
      params.append('language', language);
      params.append('content', message);

      try {  
        await api.post('/email/send', params);
  
        let normalizedLanguage = cleanUpSpecialChars(language.toLowerCase());
        history.push(`/gracias?idioma=${normalizedLanguage}`);

      } catch (error) {
        alert('Hubo un error al enviar tu mensaje. Por favor, intenta nuevamente mas tarde o escribinos a info@laboratoriodeidiomas.com.ar')
        setIsEmailSent(false);
      }
    }
  }

  return (
    <>
      <Header />

      <section id="contact">
        <div className="container">
          <div className="contact-header">
            <h2>Contacto</h2>
            <h3>¿Tenés alguna consulta?</h3>
            <p>No dudes en escribirnos.</p>
          </div>

          <form onSubmit={handleForm} id="contactpage-form">
            <div className="input-group-container">

              <div className="input-item">
                <label htmlFor="name">Nombre y apellido</label>
                <input 
                  type="text" 
                  id="name" 
                  name="name" 
                  required
                  value={name} 
                  onChange={e => setName(e.target.value)}
                />
              </div>
              
              <div className="input-item">
                <label htmlFor="email">Email</label>
                <input 
                  type="email" 
                  id="email" 
                  name="email" 
                  required
                  value={email} 
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <div className="input-item">
                <label htmlFor="phone">Teléfono/Celular</label>
                <input 
                  type="phone" 
                  id="phone" 
                  name="phone" 
                  value={phone} 
                  onChange={e => setPhone(e.target.value)}
                />
              </div>
            </div>

            <p>Seleccionar alumno/a</p>
            <div className="radio-group-container">
              <div>
                <input 
                  type="radio"
                  id="ninos"
                  name="age"
                  value={category}
                  onChange={e => setCategory("Niños")}
                />
                <label htmlFor="ninos">Niños</label>
              </div>

              <div>
                <input 
                  type="radio"
                  id="jovenes"
                  name="age"
                  value={category}
                  onChange={e => setCategory("Jóvenes")}
                  />
                <label htmlFor="jovenes">Jóvenes</label>
              </div> 

              <div>
                <input 
                  type="radio"
                  id="adultos"
                  name="age"
                  value={category}
                  onChange={e => setCategory("Adultos")}
                />
                <label htmlFor="adultos">Adultos</label>
              </div>
            </div>

            <p>Seleccionar idioma</p>
            <div className="radio-group-container">
              <div>
                <input 
                  type="radio"
                  id="ingles"
                  name="language"
                  value="ingles"
                  onChange={e => setLanguage("Inglés")}
                />
                <label htmlFor="ingles">Inglés</label>
              </div>

              <div>
                <input 
                  type="radio"
                  id="frances"
                  name="language"
                  value="frances"
                  onChange={e => setLanguage("Francés")}
                />
                <label htmlFor="frances">Francés</label>
              </div>

              <div>
                <input 
                  type="radio"
                  id="italiano"
                  name="language"
                  value="italiano"
                  onChange={e => setLanguage("Italiano")}
                />
                <label htmlFor="italiano">Italiano</label>
              </div>

              <div>
                <input 
                  type="radio"
                  id="aleman"
                  name="language"
                  value="aleman"
                  onChange={e => setLanguage("Alemán")}
                />
                <label htmlFor="aleman">Alemán</label>
              </div>

              <div>
                <input 
                  type="radio"
                  id="portugues"
                  name="language"
                  value="portugues"
                  onChange={e => setLanguage("Portugués")}
                />
                <label htmlFor="portugues">Portugués</label>
              </div>

            </div>

            <div className="text-area-container">
              <label htmlFor="message">Consulta</label>
              <textarea name="message" id="message" cols="30" rows="10" value={message} onChange={e => setMessage(e.target.value)}></textarea>
            </div>

            <button className="button button-blue" type="submit">Enviar</button>

          </form>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Contact;
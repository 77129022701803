import React from 'react';

import Header from '../../components/Header';
import ContactUs from '../../components/ContactUsBanner';
import Footer from '../../components/Footer';

import './styles.css';
import TitleBackground from '../../assets/ingles/title-background.jpg';
import KidsImage from '../../assets/ingles/ingles.png'
import AdultsImage from '../../assets/ingles/ingles2.png'

import IconClock from '../../assets/icon-clock-blue.png';
import IconLaptop from '../../assets/icon-laptop-blue.png';
import IconPerson from '../../assets/icon-person-blue.png';
import IconBook from '../../assets/icon-book-blue.png';

function Ingles() {

  return (

    <>
      <Header />


      <section id="language">

        
        <div className="banner" style={{backgroundImage: 'url(' + TitleBackground + ')'}}>
          <h1 className="language-title">Inglés</h1>
        </div>

        


        {/* <--------  NIÑOS Y JOEVES   ---------->   */}
        <div className="blue-background">

          <div className="container">
        
            <div className="language-details-young">

              <div className="course-features young">

                <h3 className="course-title">Online y presencial: niños y jóvenes</h3>
                <p className="course-duration button button-orange">Cursos Anuales</p>
                <p className="text-justify">
                Nos enfocamos en una comunicación activa a través de juegos, festejos, canciones y mímica, 
                con humor y diversión, con juguetes, láminas, disfraces y todo tipo de material didáctico. 
                ¡Para que vengas a jugar hablando en inglés!
                </p>
                
                <br></br>

                <p className="text-justify">
                Clases con material virtual, auditivo, video y diferentes recursos de aprendizaje.
                </p>

                
              </div>

                            
              <div className="course-image">
                <img src={KidsImage} alt=""/>

                <div className="image-content-container">
                  <p>Inscribete ahora</p>
                  <a href="/contacto" className="button button-yellow">Aprendé Inglés</a>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div className="container">


      {/* <--------  ADULTOS   ---------->   */}
          <div className="language-details">

            <div className="course-features">

              <h3 className="course-title">Online: adultos</h3>
              <p className="course-duration button button-orange">Cursos Cuatrimestrales</p>
              <div className="course-includes">
                <p>Incluye</p>
                <ul className="course-list">
                  <li className="course-item">
                    <img src={IconClock} alt=""/>
                    <p>
                      32 clases de 120 minutos.
                    </p>
                  </li>

                  <li className="course-item">
                    <img src={IconLaptop} alt=""/>
                    <p>
                      Cada clase dividida en 80 minutos de trabajo sincrónico (los alumnos y la 
                      profesora online al mismo tiempo) y 40 minutos de trabajo asincrónico (los 
                      alumnos y la profesora en distinto tiempo: la profesora asigna actividades 
                      escritas y auditivas, de video o de lectura complementaria y los alumnos 
                      lo realizan a su ritmo en su horario).
                    </p>
                  </li>

                  <li className="course-item">
                    <img src={IconPerson} alt=""/>
                    <p>
                      64 horas de trabajo.
                    </p>
                  </li>

                  <li className="course-item">
                    <img src={IconBook} alt=""/>
                    <p>
                      Todo el material escrito, auditivo, de video y de lectura complementaria.
                    </p>
                  </li>

                </ul>

              </div>
            </div>
            
                          
            <div className="course-image">
              <img src={AdultsImage} alt=""/>

              <div className="image-content-container">
                <p>Inscribete ahora</p>
                <a href="/contacto" className="button button-blue">Aprendé Inglés</a>
              </div>
            </div>

          </div>
        </div>
      </section>

      <ContactUs />

      <Footer />
    </>
  )
}

export default Ingles;
import React from 'react';

import './styles.css';

import facebookIcon from '../../assets/home/icono-facebook.png';
import instagramIcon from '../../assets/home/icono-instagram.png';

function Footer() {
  return (

    <section id="footer">
      <div className="container">
        <div className="row">

          <div className="col-12 col-md-4 footer-container">
            <h3>Sede Central Adrogué</h3>
            <ul>
              <li><a href="https://www.google.com/maps/place/Laboratorio+de+Idiomas+Adrogu%C3%A9/@-34.795975,-58.40156,15z/data=!4m5!3m4!1s0x0:0x6603d4a18b564777!8m2!3d-34.795975!4d-58.40156" target="_blank" rel="noopener noreferrer">Nicolás Avellaneda 139</a></li>
              <li><a href="https://wa.me/5491125507313?text=Hola,%20tengo%20interés%20en%20el%20curso%20de%20Inglés" target="_blank">11 2550-7313</a></li>
              <li><a href="mailto:info@laboratoriodeidiomas.com.ar">info@laboratoriodeidiomas.com.ar</a></li>
            </ul>
          </div>

          <div className="col-12 col-md-4 footer-container footer-courses">
            <h3>Nuestros Cursos</h3>
            <ul>
              <li><a href="/cursos/ingles">Inglés</a></li>
              <li><a href="/cursos/frances">Francés</a></li>
              <li><a href="/cursos/portugues">Portugués</a></li>
              <li><a href="/cursos/italiano">Italiano</a></li>
              <li><a href="/cursos/aleman">Alemán</a></li>
            </ul>
          </div>

          <div className="col-12 col-md-4 footer-container">
            <h3>¡Seguinos en las redes!</h3>
            <ul className="social-media">
              <li><a href="https://www.facebook.com/LabIdiomasAdrogue" target="_blank" rel="noopener noreferrer"><img src={facebookIcon} alt=""/></a></li>
              <li><a href="https://www.instagram.com/labidiomasadrogue/" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt=""/></a></li>
            </ul>
          </div>




        </div>

        <p>© {new Date().getFullYear()} - Laboratorio de Idiomas Adrogué - Todos los derechos reservados</p>
      </div>
    </section>

)
}

export default Footer;
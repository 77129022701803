import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './styles.css';
import logo from '../../assets/home/logo-laboratorio.png'

function Header() {
  const [languagesIsOpen, setLanguagesIsOpen] = useState('hidden');
  const [isMenuChecked, setIsMenuChecked] = useState(false);

  function handleMobileLanguages(e) {
    e.preventDefault();

    if (languagesIsOpen === 'hidden') {
      setLanguagesIsOpen('');
    } else {
      setLanguagesIsOpen('hidden');
    }
  }

  function toggleMobileMenu() {
    setIsMenuChecked(!isMenuChecked);
  }

  return (

    <section id="header">
      <div className="container">
        <header>
          <div className="logo"><Link to="/"><img src={logo} alt=""/></Link></div>          

          <nav>
            <a href="/">Inicio</a>
            <a href="/#about-us">Nosotros</a>
            <div className="sub-nav">
              <Link href="" className="nav-cursos">Cursos</Link>
              <div className="sub-nav-content hidden">
               <Link to="/cursos/ingles">Inglés</Link>
               <Link to="/cursos/frances">Francés</Link>
               <Link to="/cursos/portugues">Portugués</Link>
               <Link to="/cursos/italiano">Italiano</Link>
               <Link to="/cursos/aleman">Alemán</Link>
              </div>
            </div>
            <Link to="/contacto">Contacto</Link>
          </nav>

          <div className="mobile-menu-wrap hidden">
            <input type="checkbox" className="toggler" checked={isMenuChecked} onClick={() => {setLanguagesIsOpen('hidden'); toggleMobileMenu()}} />
            <div className="hamburger" onClick={() => setLanguagesIsOpen('hidden')}><div></div></div>
            <div className="menu">
              <div>
                <div>
                  <ul>
                    <li onClick={toggleMobileMenu}><Link to="/">Inicio</Link></li>
                    <li onClick={toggleMobileMenu}><a href="/#about-us">Nosotros</a></li>
                    <li><a href="" onClick={handleMobileLanguages}>Cursos</a>
                      <ul className={'mobile-sub-menu ' + languagesIsOpen}>
                        <li onClick={toggleMobileMenu}><Link to="/cursos/ingles">Inglés</Link></li>
                        <li onClick={toggleMobileMenu}><Link to="/cursos/frances">Francés</Link></li>
                        <li onClick={toggleMobileMenu}><Link to="/cursos/portugues">Portugués</Link></li>
                        <li onClick={toggleMobileMenu}><Link to="/cursos/italiano">Italiano</Link></li>
                        <li onClick={toggleMobileMenu}><Link to="/cursos/aleman">Alemán</Link></li>
                      </ul>
                    </li>
                    <li onClick={toggleMobileMenu}><Link to="/contacto">Contacto</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </header>
      </div>
    </section>
  )
}

export default Header;
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ScrollToTop from './utils/scrolltop';

import Home from './pages/Home';
import Contact from './pages/Contact';
import Ingles from './pages/Languages/ingles';
import Portugues from './pages/Languages/portugues';
import Aleman from './pages/Languages/aleman';
import Frances from './pages/Languages/frances';
import Italiano from './pages/Languages/italiano';
import Thanks from './pages/Thanks';
//import Spanish from './pages/Languages/spanish';


export default function Routes() {

  return(
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/contacto" component={Contact}/>
          <Route exact path="/cursos/ingles" component={Ingles}/>
          <Route exact path="/cursos/portugues" component={Portugues}/>
          <Route exact path="/cursos/aleman" component={Aleman}/>
          <Route exact path="/cursos/frances" component={Frances}/>
          <Route exact path="/cursos/italiano" component={Italiano}/>
          <Route exact path="/gracias" component={Thanks}/>
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  )
}